<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalVideo"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body" style="text-align: left; padding: 0">
          <i
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="cerrarModal()"
            class="fal fa-times"
            style="
              position: absolute;
              z-index: 1;
              top: 10px;
              cursor: pointer;
              right: 23px;
              font-size: 28px;
            "
          ></i>

          <video id="mi-video" controls style="width: 100%">
            <source src="video-wacheckin.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nombre: null,
      telefono: null,
      email: null,
      tipo: null,
      numero: null,
      asunto: null,
      llego: null,
      loader: false,
      enviado: false,
    };
  },
  methods: {

  cerrarModal() {
  var video = document.getElementById("mi-video");
  video.pause();
   }


  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 768px) {
  .modal-body {
    padding: 30px 88px;
  }
}

.contenedor-loader {
  position: absolute;
  background: rgb(232 232 232 / 64%);
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4caf50 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-control {
  min-height: 50px;
  background: #f7f7f7;
}
</style>
