export const politicas = {

    p1: `Política de privacidad de alojamientos`,
    p2: `El marco normativo general es el RGPD europeo 679/2016 sobre protección de
    datos personales.`,
    p3: `1. General`,
    p4: `Para habilitar el uso de la aplicación WACheckin y del resto de plataformas en
    las que se ofrecen los servicios de WACheckin, es necesario ser usuario
    registrado de la misma y aceptar las reglas de privacidad que se detallan a
    continuación a tal efecto, de acuerdo con las establecidas en el Reglamento
    Europeo de Protección de Datos (Reglamento 2016/679) y las leyes específicas
    del país y la región donde se encuentra el sujeto de los datos a tratar.`,
    p5: `Al utilizar los servicios de WACheckin, y en consecuencia aceptar su política
    de privacidad, usted acepta que el propietario del alojamiento, responsable de
    sus datos personales, almacene y procese los datos personales que usted nos
    proporciona con el fin de utilizar la plataforma. En todo caso, se informa al
    huésped que los siguientes tratamientos se realizan en base a la información
    facilitada.`,
    p6: `2. ¿Qué hacemos con sus datos?`,
    p7: `Los tratamientos, y por tanto, el uso de datos personales que se lleva a cabo
    son los siguientes:`,
    p8: `-Gestión de estancias, con el fin de controlar la estancia en los
    alojamientos.`,
    p9: `-Información a las Autoridades, según lo requiera las normativas turísticas,
    ya que puede existir obligación legal de hacerlo.`,
    p10: `3. ¿Qué datos personales tratamos en la aplicación?`,
    p11: `Los siguientes datos podrían ser procesados
    &ZeroWidthSpace;&ZeroWidthSpace;(hay algunas regiones donde la recopilación de
    algunos datos no es obligatoria) para administrar su estancia y enviar
    información a las Autoridades: Nombre, Apellido, Segundo Apellido, Sexo,
    Nacionalidad, Nacionalidad, Fecha de Nacimiento, Nacimiento País, ciudad de
    nacimiento, país de residencia, ciudad de residencia, dirección de residencia,
    tipo de identificación, número de identificación, fecha de identificación,
    país de identificación, ciudad de emisión, propósito de la estadía, número de
    visa, firma.`,
    p12: `4. ¿Cuánto tiempo se almacenan?`,
    p13: ` En general, almacenamos la información durante el tiempo necesario para
    realizar los diferentes tratamientos, y al menos, de acuerdo con la normativa
    turística, por un período de 3 años.`,
    p14: `5. ¿A quién transferimos los datos?`,
    p15: `Cualquier dato que sea recabado por el titular del alojamiento, en ningún
    caso, será cedido a terceras empresas, excepto en los casos que se detallan a
    continuación:`,
    p16: `-Todos los tratamientos antes mencionados son cedidos exclusivamente a
    WACheckin a los efectos de la gestión completa del registro de huéspedes y
    demás servicios convenidos entre WACheckin y el titular del alojamiento.`,
    p17: `-La transferencia de tratamientos a las Autoridades.`,
    p18: `6. ¿Tomamos decisiones automatizadas?`,
    p19: `No, actualmente no se están tomando decisiones de tratamiento automatizadas.`,
    p20: `7. Derechos de acceso, rectificación, supresión, limitación del tratamiento,
    portabilidad de datos y oposición.`,
    p21: `De acuerdo con el Reglamento General de Protección de Datos, junto con su
    derecho a oponerse a la automatización de decisiones, cualquier usuario que
    haya facilitado datos al titular del alojamiento tiene los siguientes derechos
    que puede ejercitar:`,
    p22: `-Acceso.<br />-Rectificación.<br />-Supresión.<br />-Limitación de
    tratamiento.<br />-Portabilidad de los datos.<br />-Oposición.`,
    p23: `El ejercicio de estos derechos deberá realizarse mediante comunicación
    dirigida al titular del alojamiento, bien a la dirección postal, bien a la
    dirección electrónica que dicho titular debe facilitar, sin perjuicio de la
    utilización de cualquier otro medio válido en derecho que permita cumplir con
    los requisitos legalmente válidos.`,
    p24: `Para acreditar su identidad en caso de que desee ejercer los derechos antes
    mencionados, necesitaremos que nos facilite:`,
    p25: `-Nombre y apellidos del interesado; copia de DNI, pasaporte o similar y, en su
    caso, de la persona que lo represente.`,
    p26: `-Objeto de la solicitud.`,
    p27: `-Dirección a efectos de notificación, fecha y firma del solicitante.`,
    p28: `-Documentos que sustenten la petición formulada en caso de que se estimen
    convenientes o necesarios.`,

}