export const qrcheckin = {
    p1: `check-in por QR`,
    p2: `Elimina las largas esperas en la recepción. Con nuestro sistema de check-in online, tus huéspedes pueden completar el proceso desde la comodidad de su dispositivo.`,
    p3: `Prueba gratis`,
    p4: `Descubre la comodidad y eficiencia del check-in online`,
    p5: `Estamos aquí para transformar la manera en que tus huéspedes inician su experiencia en tu hotel`,
    p6: `Sin Filas, Sin Esperas`,
    p7: `Acceso Instantáneo vía WhatsApp`,
    p8: `Personalización Total`,
    p9: `Check-ins en dos pasos, fácil y rápido`,
    p10: `Mensajes Automáticos Programados`,
    p11: `Comenzar`,
    p12: `Simplifica la llegada de tus huéspedes `,
    p13: `Comienza a construir experiencias memorables desde el primer momento`,
    p14: `Permite que tus huéspedes realicen su check-in de manera rápida y sin complicaciones a traves de enlace vía WhatsApp`,
    p15:'Bienvenido al Futuro de la Llegada sin Estrés',
}