export const police = {
    p1: `Envío a SES Hospedajes`,
    p2: `¡Cumple con las normas y regulaciones de tu país de forma automática con nuestro sistema de envío de check-ins a SES Hospedajes!`,
    p3: `Comenzar`,
    p4: `Envío automático a las autoridades correspondientes`,
    p5: `Ventajas de utilizar nuestro servicio automático`,
    p6: `Nuestro sistema se integra perfectamente con tu sistema de gestión de propiedades existente, lo que significa que no hay necesidad de cambiar tus procesos actuales.`,
    p7: `En caso de una emergencia o situación de seguridad, la policía migratoria tendrá acceso inmediato a los check-ins de tus huéspedes, lo que puede ayudar a acelerar su respuesta y proteger a tus huéspedes.`,
    p8: `Al proporcionar información importante a la policía migratoria, nuestro sistema ayuda a mantener a tus huéspedes seguros y protegidos durante su estancia en tu propiedad`,
    p9: `Nuestro servicio automatizado de envío de check-ins a la policía migratoria te ayudará a cumplir con las regulaciones locales, evitando posibles multas o sanciones.`,
    p10: `Comenzar`,
    p11: `Ahorra tiempo y quebraderos de cabeza`,
    p12: `Envío automático de partes de viajeros a las autoridades`,
    p13: `Funciona de la siguiente manera: Una vez tus huéspedes hayan completado el
    check-in online, generamos los partes de viajero y los enviamos
    automáticamente a las autoridades competentes durante las 24 horas posteriores
    a la fecha de entrada, tal y cómo exige la ley.`,
    p14: `Términos y
    condiciones`,
    p15: ``,
    p16: ``,
    p17: ``,
    p18: ``,
    p19: ``,
}