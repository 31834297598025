export const terminos = {
    p1:`Términos de Uso`,
    p2:`INFORMACIÓN GENERAL`,
    p3:`Esta página, su contenido e infraestructura, y el servicio de gestión de
    reservas de alojamientos que proporcionamos en esta página y a través de este
    sitio web pertenecen, son gestionadas y administradas por QRCHECKIN LLC (en
    adelante WACheckin). En todo el sitio web, así como en la App de WACheckin y
    en cualquiera de los productos que la empresa ofrece (en adelante al hablar
    del sitio web o de la web de WACheckin, estaremos haciendo referencia a todas
    las plataformas en las que WACheckin provee sus servicios y toda la
    información contenida en ellas), los términos “nosotros”, “nos” y “nuestro” se
    refieren a WACheckin. WACheckin ofrece este sitio web, y el usuario, está
    condicionado a la aceptación de todos los términos, condiciones, políticas y
    notificaciones aquí establecidos.`,
    p4:`Al visitar nuestro sitio web, y/o comprar alguno de nuestros productos, usted
    participa en nuestro “Servicio” y acepta los siguientes términos y condiciones
    (“Términos de Servicio”, “Términos”), incluidos todos los términos y
    condiciones adicionales y las políticas a las que se hace referencia en estos
    Términos, así como las políticas y condiciones disponibles a través de
    hipervínculos. Estas condiciones de servicio se aplican a todos los usuarios
    de WACheckin, incluyendo a usuarios que sean navegadores, proveedores,
    clientes, y/o colaboradores, sin que esta lista sirva de limitación alguna.`,
    p5:`Por favor, lea estos Términos de Servicio cuidadosamente antes de acceder o
    utilizar nuestro sitio web. Al acceder o utilizar cualquier parte del sitio,
    está usted aceptando los Términos de Servicio. Si no está de acuerdo con todos
    los términos y condiciones, no debería acceder a la página web o usar
    cualquiera de nuestros servicios.`,
    p6:`Cualquier función nueva o herramienta que se añada a nuestra oferta de
    productos y servicios, también estará sujeta a los Términos de Servicio. Puede
    usted revisar la versión actualizada de los Términos en cualquier momento en
    esta página. WACheckin se reserva el derecho de actualizar, cambiar o
    reemplazar cualquier parte de los Términos de Servicio mediante la publicación
    de actualizaciones y/o cambios en nuestro sitio web. Es responsabilidad del
    usuario revisar dichos Términos periódicamente para verificar si hay cambios.
    Su uso continuado o el acceso al sitio web después de la publicación de
    cualquier cambio constituye la aceptación de dichos cambios.`,
    p7:`Estos términos han sido redactados de conformidad con lo dispuesto en la
    normativa vigente aplicable en materia de Internet, comercio electrónico y
    protección de datos, entre otras.`,
    p8:`SECCIÓN 1 - CONDICIONES GENERALES`,
    p9:`Usted no puede usar los productos de WACheckin con ningún propósito ilegal o
    no autorizado, tampoco puede, en el uso del Servicio, violar cualquier ley que
    le sea aplicable. No debe transmitir gusanos, virus o cualquier código de
    naturaleza destructiva.`,
    p10:`En adición a las prohibiciones mencionadas, se prohíbe el uso del sitio web o
    su contenido: (a) para pedirle a otros que realicen o participen en actos
    ilícitos; (b) para infringir o violar el derecho de propiedad intelectual de
    WACheckin o de terceras partes; (c) para acosar, abusar, insultar, dañar,
    difamar, calumniar, desprestigiar, intimidar o discriminar por razones de
    género, orientación sexual, religión, etnia, raza, edad, nacionalidad o
    discapacidad; (d) para presentar información falsa o engañosa; (e) para
    recopilar o rastrear información personal de otros; (f) para generar spam,
    phish, pharm, pretext, spider, crawl, o scrape; (g) para cualquier propósito
    obsceno o inmoral; o (h) para interferir o burlar los elementos de seguridad
    del Servicio.`,
    p11:`Nos reservamos el derecho de suspender el uso del Servicio o de cualquier
    sitio web relacionado por violar cualquiera de los ítems de los usos
    prohibidos.`,
    p12:`El incumplimiento o violación de cualquiera de estos Términos darán lugar al
    cese inmediato de los Servicios y al ejercicio de las acciones legales y de
    resarcimiento que sean necesarias.`,
    p13:`WACheckin se reserva el derecho a rechazar la prestación de los Servicios a
    cualquier persona, por cualquier motivo y en cualquier momento.`,
    p14:`Usted se compromete a no reproducir, duplicar, copiar, vender, revender o
    explotar cualquier parte del Servicio, uso del Servicio, o acceso al Servicio
    o cualquier contacto en el sitio web a través del cual se presta el servicio
    sin el permiso por escrito de WACheckin.`,
    p15:`Los títulos y encabezados utilizados en la redacción se incluyen solo por
    conveniencia y no limita o afecta a estos Términos.`,
    p16:` SECCIÓN 2 - EXACTITUD, EXHAUSTIVIDAD Y ACTUALIDAD DE LA INFORMACIÓN`,
    p17:`WACheckin no se hace responsable si la información disponible en este sitio
    web no es exacta, completa o actual. El material en este sitio es provisto
    sólo con fines de información general y no debe confiarse en ella o utilizarse
    como la única base para la toma de decisiones sin consultar previamente,
    información más precisa, completa u oportuna.`,
    p18:`Este sitio web puede contener cierta información histórica o pasada. Dicha
    información no es necesariamente actual y se provee únicamente como
    referencia. WACheckin se reserva el derecho a modificar los contenidos de este
    sitio web en cualquier momento, pero no se obliga a actualizar la información.
    Usted acepta que es su responsabilidad revisar y comprobar los cambios en
    nuestro sitio web.`,
    p19:`SECCIÓN 3 - MODIFICACIONES AL SERVICIO Y PRECIOS`,
    p20:`Los precios de nuestros productos están sujetos a posibles variaciones sin que
    sea necesario practicar un previo aviso. Así mismo WACheckin se reserva el
    derecho a modificar o suspender el Servicio (o cualquier parte del mismo) en
    cualquier momento sin previo aviso.`,
    p21:`WACheckin no es responsable ante usted o ante una tercera parte por cualquier
    modificación, cambio de precio, suspensión o discontinuidad del Servicio.`,
    p22:`SECCIÓN 4 - PRODUCTOS Y SERVICIOS`,
    p23:`Ciertos productos o servicios pueden estar disponibles exclusivamente a
    discreción de WACheckin. Estos productos o servicios pueden estar sujetos a
    devolución o cambio de acuerdo con nuestra “Política de Devolución”.`,
    p24:`Todas las descripciones de productos o precios de los productos están sujetas
    a cambios en cualquier momento y sin previo aviso, a la sola discreción de
    WACheckin. WACheckin no se hace responsable de las expectativas acerca de sus
    productos y servicios generadas en este sitio web o través de nuestras
    campañas de comunicación comercial y no garantiza que los errores en el
    Servicio sean corregidos. WACheckin se reserva el derecho de suspender
    cualquier producto en cualquier momento. Cualquier oferta de un producto o
    servicio hecha en este sitio web es nula donde pueda contravenir las leyes.`,
    p25:`Puede ocurrir que haya información en la web de WACheckin o en el contenido
    del Servicio que contenga errores tipográficos, inexactitudes u omisiones que
    puedan estar relacionadas con las descripciones de productos, precios,
    promociones, ofertas, tiempo de tránsito y disponibilidad. WACheckin se
    reserva el derecho a corregir dichos errores, inexactitudes u omisiones en
    cualquier momento sin previo aviso.`,


    p26:`SECCIÓN 5 - POLÍTICA DE DEVOLUCIÓN`,
    p27:`WACheckin es una empresa que ofrece sus servicios bajo un modelo de
    suscripción, el cliente paga una tarifa de forma periódica (mensual o anual) a
    cambio de unos servicios que se prestan una vez recibido el pago. El cliente
    una vez introduce los datos de pago (número de tarjeta, fecha de vencimiento y
    cualquier otra información necesaria para que se tramite la transacción) da su
    consentimiento a WACheckin para que realice los cobros pertinentes a través de
    dicho método de pago.`,
    p28:`Es responsabilidad del cliente vigilar y revisar su período de facturación y
    el próximo cargo que se va a realizar, de modo que si no desea continuar con
    el servicio es dicho cliente quien debe dar de baja el servicio, o de lo
    contrario el próximo cargo se realizará y no se generará derecho alguno a
    reembolso, dado que una vez realizado el pago, los servicios de WACheckin
    estarán disponibles para el uso por parte del cliente durante el tiempo de
    suscripción restante.`,
    p29:`No obstante, WACheckin se reserva el derecho a reembolsar estudiando el caso
    concreto y siempre atendiendo a razones objetivas y justas, como una caída del
    servicio que no permita dar de baja la suscripción o un plagio de tarjeta
    bancaria.`,
    p30:`SECCIÓN 6 - EXACTITUD DE FACTURACIÓN E INFORMACIÓN DE CUENTA`,
    p31:`Usted se compromete a proporcionar información actual, completa y precisa
    relativa a los detalles de su cuenta, información de facturación, datos de
    contacto y demás información necesaria para el correcto funcionamiento de los
    Servicios. Usted se compromete a actualizar su información de pago (números de
    tarjeta, fecha de vencimiento, etc.), para que podamos completar sus
    transacciones.`,
    p32:`SECCIÓN 7 - HERRAMIENTAS OPCIONALES Y ENLACES DE TERCEROS`,
    p33:`Es posible que le proporcionemos a usted acceso a herramientas/plataformas de
    terceros sobre las que no tenemos control ni entrada. Usted reconoce y acepta
    que proporcionamos acceso a este tipo de herramientas “tal cual” y “según
    disponibilidad” sin garantías, representaciones o condiciones de ningún tipo y
    sin ningún respaldo. No tendremos responsabilidad alguna derivada de o
    relacionada con el uso de herramientas proporcionadas por terceras partes.
    Cualquier uso que usted haga de dichas herramientas opcionales que se ofrecen
    a través del sitio web será bajo su propio riesgo y discreción y deberá usted
    asegurarse de estar familiarizado y aceptar los términos bajo los cuales estas
    herramientas son proporcionadas.`,
    p34:`Cierto contenido, productos y/o servicios que WACheckin ofrece puede incluir
    material de terceras partes. Hay enlaces de terceras partes en este sitio web
    que pueden redireccionarle a sitios web que no están bajo nuestro control. No
    nos responsabilizamos de examinar o evaluar el contenido o exactitud y no
    garantizamos ni tendremos ninguna obligación o responsabilidad por cualquier
    material de terceros.`,

    p35:`SECCIÓN 8 - INFORMACIÓN PERSONAL`,
    p36:`El tratamiento de información personal por parte de WACheckin se rige por
    nuestra Política de Privacidad. Los datos confiados a WACheckin serán tratados
    de forma confidencial y quedarán incorporados a los respectivos ficheros de
    los que es responsable QRCHECKIN LLC con domicilio social en 407 Lincoln Rd
    Ste 6h #1296, 33139 Miami Beach, FL United States, una vez que el usuario haya
    dado su consentimiento expreso.`,
    p37:`Mediante dicho consentimiento, WACheckin obtiene el permiso para recabar los
    datos de los huéspedes con la única finalidad de proceder al registro de los
    mismos en las plataformas destinadas a tal efecto por los Cuerpos y Fuerzas de
    Seguridad del Estado, convirtiéndose en encargado de dicho tratamiento, y
    siendo el usuario titular del alojamiento el responsable del tratamiento de
    los datos. Para formalizar este encargo de tratamiento WACheckin y el usuario
    deberán firmar un contrato de encargo de tratamiento. En su defecto, el
    usuario podrá, mediante la aceptación de estos Términos acogerse al encargo de
    tratamiento genérico.`,
    p38:`A colación del párrafo anterior, WACheckin informa a los usuarios titulares de
    los alojamientos que deberán ofrecer a los huéspedes una política de
    privacidad en la que se indique qué datos van a ser tratados y con qué
    finalidad, y en la que se establezca que WACheckin es el encargado del
    tratamiento y ellos mismos (titulares del alojamiento) son los responsables.`,
    p39:`Si los usuarios de WACheckin no ofrecen una política de privacidad a sus
    huéspedes, el tratamiento de los datos podrá regirse por la siguiente política
    de privacidad genérica.`,
    p40:`El tratamiento de los datos de carácter personal de los usuarios llevado a
    cabo por WACheckin, se realiza conforme a lo previsto en la Ley Orgánica
    3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los
    derechos digitales, en desarrollo del Reglamento (UE) 2016/679 del Parlamento
    Europeo y del Consejo de 27 de abril de 2016, relativo a la protección de las
    personas físicas en lo que respecta al tratamiento de datos personales y a la
    libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE,
    así como cualquier otra disposición que tenga por finalidad el desarrollo de
    ese cuerpo normativo.`,
    p41:`SECCIÓN 9 - LIMITACIÓN DE RESPONSABILIDAD`,
    p42:`Sujeto a las limitaciones establecidas en estos Términos y en la medida
    permitida por la ley, solo seremos responsables de los daños directos
    realmente sufridos, pagados o incurridos por usted debido a una deficiencia
    atribuible a las obligaciones de WACheckin con respecto a los servicios que la
    empresa ofrece.`,
    p43:`Sin embargo, y en la medida permitida por la ley, ni WACheckin ni ninguno de
    sus directores, empleados, representantes, compañías afiliadas,
    distribuidores, licenciatarios, agentes u otros involucrados en la creación,
    patrocinio, promoción de WACheckin y su contenido será responsable de (a)
    interrupciones, errores o suspensiones inoportunos, (b) la exactitud y
    fiabilidad de los datos, (c) cualquier daño directo, indirecto, incidental o
    consecuente de cualquier tipo, incluidos, entre otros, pérdida de ingresos,
    pérdida de datos, costes de reemplazo o cualquier daño similar, ya sea por
    agravio (incluida negligencia), responsabilidad directa o de otro tipo, que
    surja del uso de cualquiera de los servicios o productos adquiridos a través
    del Servicio, o para cualquier otra reclamación relacionada de alguna manera
    con el uso del Servicio o cualquier producto, incluidos, entre otros,
    cualquier error u omisión en cualquier contenido, o cualquier pérdida o daño
    de cualquier tipo incurrido como resultado del uso del Servicio o cualquier
    contenido (o producto) publicado, transmitido o puesto a disposición a través
    del Servicio, incluso si se informa de dicha posibilidad. Debido a que algunos
    estados o jurisdicciones no permiten la exclusión o limitación de
    responsabilidad por daños consecuentes o incidentales, en dichos estados o
    jurisdicciones, la responsabilidad de WACheckin se limitará en la medida
    máxima permitida por la ley.`,
    p44:`En el uso de la Plataforma, es responsabilidad del usuario (a) verificar la
    información sobre la identificación del huésped por cualquier medio que
    considere y que sea acorde con la normativa vigente, (b) verificar el correcto
    envío de la información, datos de los huéspedes y partes de viajeros, así como
    cualquier otro requisito que la legislación vigente exija, a la autoridad o
    administración correspondiente, (c) cumplir con las condiciones necesarias
    para garantizar la lectura precisa del escaneo del ID del huésped, (d) y
    cumplir con los posibles requisitos adicionales en el registro de huéspedes
    que la legislación pueda establecer y que no estén incluidos en los servicios
    prestados por WACheckin.`,

    p45:`SECCIÓN 10 - INDEMNIZACIÓN`,
    p46:`Usted acepta indemnizar, defender y/o mantener indemne a WACheckin, sus
    filiales, afiliados, socios, empleados, directores, agentes, contratistas,
    concesionarios, proveedores de servicios y subcontratistas, de cualquier
    reclamación o demanda, incluyendo honorarios razonables de abogados, hecha por
    cualquier tercero a causa o como resultado del incumplimiento de usted de los
    Términos o de las condiciones y políticas que se incorporan a estos como
    referencia, o la violación de cualquier ley o de los derechos de un tercero.`,
    p47:`SECCIÓN 11 - DIVISIBILIDAD`,
    p48:`En caso de que se determine que cualquier disposición de estos Términos sea
    ilegal, nula o inaplicable, dicha disposición se considerará separada de estos
    Términos, y dicha determinación no afectará a la validez de las demás
    disposiciones.`,
    p49:`SECCIÓN 12 - LEY`,
    p50:`Estos Términos y cualquier otra condición, política o acuerdo bajo el cual
    WACheckin proporcione sus servicios se regirán e interpretarán en conformidad
    con las leyes españolas y, siguiendo el orden de prelación de las normas
    establecido en el derecho español, atendiendo a las normativas autonómicas de
    Madrid y a las regulaciones locales de la ciudad de Madrid.`,

    p51:`SECCIÓN 13 - CONTACTO`,
    p52:`Cualquier cuestión acerca de los Términos de Servicio deberá ser remitida a
    soporte@WACheckin.es.`,
    p53:`SECCIÓN 14 - IDIOMA`,
    p54:`El idioma aplicable a estos Términos es el español. Cualquier versión de los
    mismos en idioma distinto es ofrecida para comodidad del Usuario y para
    facilitar su comprensión. No obstante, estos Términos se regirán siempre por
    su versión en español.`,
    p55:`En caso de contradicción entre los Términos en cualquier idioma y su versión
    en español, prevalecerá la versión en español.`,

    




    p56:`Política de Privacidad`,
    p57:`Responsable del tratamiento`,
    p58:`El responsable del tratamiento de los datos recabados es QRCHECKIN LLC con
    domicilio social en 407 Lincoln Rd Ste 6h #1296, 33139 Miami Beach, FL United
    States, titular de la App WACheckin y la plataforma web WACheckin.`,
    p59:`La presente Política de Privacidad regula la recogida y tratamiento de los
    datos personales que sean proporcionados por los titulares de los alojamientos
    cuando accedan y hagan uso de las funcionalidades de la App WACheckin y la
    plataforma web WACheckin.`,
    p60:`Todos los datos serán tratados siguiendo las disposiciones presentes en el
    Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril
    de 2016 relativo a la protección de las personas físicas en lo que respecta al
    tratamiento de datos personales y a la libre circulación de estos datos y por
    el que se deroga la Directiva 95/46/CE (Reglamento general de protección de
    datos).`,
    p61:`Recogida de datos, finalidades, base de legitimación y plazos de conservación:`,
    p62:`a. Alta en la App/plataforma web y registro del titular del alojamiento:`,
    p63:`Mediante dicho registro, será necesario que el usuario titular del alojamiento
    proporcione sus datos identificativos, como nombre, correo electrónico y
    contraseña. Los datos serán tratados para darle de alta en la aplicación y la
    plataforma web y permitirle el acceso a los servicios que ofrece.`,
    p64:`La base jurídica del tratamiento de tales datos se sustenta en el cumplimiento
    del contrato o licencia de uso.`,
    p65:`El plazo de conservación de los datos para esta finalidad se mantendrá durante
    el tiempo que el titular permanezca dado de alta y no hubiera ejercitado la
    baja de la aplicación.`,
    p66:`b. Suscripción:`,
    p67:`El titular del alojamiento puede suscribirse a los servicios que ofrece
    WACheckin. Para ello, deberá facilitar los datos de su tarjeta de
    crédito/débito. Los datos serán tratados para tramitar la gestión del pago de
    los servicios.`,
    p68:`La base jurídica del tratamiento de tales datos se sustenta en el cumplimiento
    del contrato o licencia de uso.`,
    p69:`Los datos serán conservados durante el plazo requerido para cumplir con
    obligaciones legales o para poder atender reclamaciones o responsabilidades
    derivadas del tratamiento.`,
    p70:`c. Cookies:`,
    p71:`La App y la web disponen de tecnología para la implantación de archivos
    denominados cookies en el equipo que sea utilizado para el acceso y navegación
    por la App y la web. Las cookies pueden ser bloqueadas o deshabilitadas en
    cualquier momento a través de las opciones de configuración del navegador.`,
    p72:`La base jurídica del tratamiento de tales datos se sustenta en el
    consentimiento del Usuario.`,
    p73:`d. Publicidad:`,
    p74:`WACheckin podrá tratar los datos de los usuarios titulares de los alojamientos
    para mantenerles actualizados de su actividad y novedades respecto de
    información relacionada con sus servicios en base a su interés legítimo por
    medios electrónicos. El titular puede oponerse a esto en el momento de su alta
    en la App/plataforma web o ejercitar su derecho de oposición.`,
    p75:`El plazo de conservación de los datos será el tiempo que permanezca dado de
    alta en nuestros sistema para el envío de publicidad y no haya revocado su
    consentimiento.`,
    p76:`Ejercicio de derechos`,
    p77:`Asimismo, el Usuario puede revocar el consentimiento para el tratamiento,
    haciendo ejercicio de los derechos de acceso, rectificación, supresión,
    portabilidad, oposición y limitación al tratamiento, poniéndolo en
    conocimiento de WACheckin a través de un correo electrónico dirigido a
    soporte@WACheckin.es señalando como asunto “Protección de
    Datos”. En alguna ocasión especial y según las circunstancias, podremos
    solicitar al Usuario que acredite su identidad mediante documento oficial, por
    ejemplo para para evitar el acceso a sus datos por parte de terceros cuando
    pueda haber sospecha de fraude.`,
    p78:`Por último, el Usuario podrá comunicar cualquier modificación por escrito o
    solicitar la baja, indicando esto en cualquiera de las direcciones
    facilitadas.`,
    p79:`En cualquier momento, el Usuario podrá presentar una reclamación ante la
    Agencia Española de Protección de Datos para hacer valer sus derechos.`,
    p80:`Modificación de la Política de privacidad`,
    p81:`WACheckin se reserva el derecho de modificar en cualquier momento su política
    de privacidad, respetando la legislación vigente en materia de protección de
    datos y previa la debida comunicación a los interesados.`,
    p82:`Idioma aplicable a la presente política de privacidad`,
    p83:`El idioma aplicable a esta Política de Privacidad es el español. Cualquier
    versión de la misma en idioma distinto es ofrecida para comodidad del Usuario
    y para facilitar su comprensión. No obstante, esta Política de Privacidad se
    regirá siempre por su versión en español.`,
    p84:`En caso de contradicción entre la Política de Privacidad en cualquier idioma y
    su versión en español, prevalecerá la versión en español.`,


    p85:`Política de privacidad genérica de los alojamientos`,
    p86:`El marco normativo general es el RGPD europeo 679/2016 sobre protección de
    datos personales.`,
    p87:`1. General`,
    p88:`Para habilitar el uso de la aplicación WACheckin y del resto de plataformas en
    las que se ofrecen los servicios de WACheckin, es necesario ser usuario
    registrado de la misma y aceptar las reglas de privacidad que se detallan a
    continuación a tal efecto, de acuerdo con las establecidas en el Reglamento
    Europeo de Protección de Datos (Reglamento 2016/679) y las leyes específicas
    del país y la región donde se encuentra el sujeto de los datos a tratar.`,
    p89:`Al utilizar los servicios de WACheckin, y en consecuencia aceptar su política
    de privacidad, usted acepta que el propietario del alojamiento, responsable de
    sus datos personales, almacene y procese los datos personales que usted nos
    proporciona con el fin de utilizar la plataforma. En todo caso, se informa al
    huésped que los siguientes tratamientos se realizan en base a la información
    facilitada.`,
    p90:`2. ¿Qué hacemos con sus datos?`,
    p91:`Los tratamientos, y por tanto, el uso de datos personales que se lleva a cabo
    son los siguientes:`,
    p92:`- Gestión de estancias, con el fin de controlar la estancia en los
    alojamientos.`,
    p93:`- Información a las Autoridades, según lo requiera las normativas turísticas,
    ya que puede existir obligación legal de hacerlo.`,
    p94:`3. ¿Qué datos personales tratamos en la aplicación?`,
    p95:`Los siguientes datos podrían ser procesados (hay algunas regiones donde la
        recopilación de algunos datos no es obligatoria) para administrar su estancia
        y enviar información a las Autoridades: Nombre, Apellido, Segundo Apellido,
        Sexo, Nacionalidad, Nacionalidad, Fecha de Nacimiento, Nacimiento País, ciudad
        de nacimiento, país de residencia, ciudad de residencia, dirección de
        residencia, tipo de identificación, número de identificación, fecha de
        identificación, país de identificación, ciudad de emisión, propósito de la
        estadía, número de visa, firma.`,
    p96:`4. ¿Cuánto tiempo se almacenan?`,
    p97:`En general, almacenamos la información durante el tiempo necesario para
    realizar los diferentes tratamientos, y al menos, de acuerdo con la normativa
    turística, por un período de 3 años.`,
    p98:`5. ¿A quién transferimos los datos?`,
    p99:`Cualquier dato que sea recabado por el titular del alojamiento, en ningún
    caso, será cedido a terceras empresas, excepto en los casos que se detallan a
    continuación:`,
    p100:`- Todos los tratamientos antes mencionados son cedidos exclusivamente a
    WACheckin a los efectos de la gestión completa del registro de huéspedes y
    demás servicios convenidos entre WACheckin y el titular del alojamiento.`,
    p101:`- La transferencia de tratamientos a las Autoridades.`,
    p102:`6. ¿Tomamos decisiones automatizadas?`,
    p103:`No, actualmente no se están tomando decisiones de tratamiento automatizadas.`,
    p104:`7. Derechos de acceso, rectificación, supresión, limitación del tratamiento,
    portabilidad de datos y oposición.`,
    p105:`De acuerdo con el Reglamento General de Protección de Datos, junto con su
    derecho a oponerse a la automatización de decisiones, cualquier usuario que
    haya facilitado datos al titular del alojamiento tiene los siguientes derechos
    que puede ejercitar:`,
    p106:`Acceso.`,
    p107:`Rectificación.`,
    p108:`Supresión.`,
    p109:`Limitación de tratamiento.`,
    p110:`Portabilidad de los datos.`,
    p111:`Oposición.`,
    p112:`El ejercicio de estos derechos deberá realizarse mediante comunicación
    dirigida al titular del alojamiento, bien a la dirección postal, bien a la
    dirección electrónica que dicho titular debe facilitar, sin perjuicio de la
    utilización de cualquier otro medio válido en derecho que permita cumplir con
    los requisitos legalmente válidos.`,
    p113:`Para acreditar su identidad en caso de que desee ejercer los derechos antes
    mencionados, necesitaremos que nos facilite:`,
    p114:`- Nombre y apellidos del interesado; copia de DNI, pasaporte o similar y, en
    su caso, de la persona que lo represente.`,
    p115:`- Objeto de la solicitud.`,
    p116:`- Dirección a efectos de notificación, fecha y firma del solicitante.`,
    p117:` - Documentos que sustenten la petición formulada en caso de que se estimen
    convenientes o necesarios.`,
    p118:``,
    p119:``,
    p120:``,


}